@import "~antd/dist/antd.css";

.container {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.ant-tag-has-color {
  color: #000 !important;
}

.ant-form-item {
  margin-bottom: 24px !important;
}

.ant-form-item-explain {
  display: none;
}

.ant-input-number-handler-wrap {
  display: none;
}

td.ant-table-column-sort {
  background: unset;
}

.list-image {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #d9d9d9;
}

.ant-statistic-content-prefix {
  margin-right: 0;
}

.ant-upload-list-item-thumbnail img {
  width: auto;
  object-fit: contain;
}

.ant-statistic-content {
  font-size: 14px;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0 !important;
}

.ant-upload-list-item-list-type-picture {
  cursor: pointer;
}

.ant-upload-list-item-name {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #06c755;
  border-radius: 10px;
}

.form-show-explain .ant-form-item-explain {
  display: block;
}

.form-hide-item-label .ant-form-item-label {
  display: none;
}