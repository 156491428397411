.errorResult :global .ant-result-icon {
  display: none;
}

.errorFallback {
  width: 100%;
  height: 100%;
  background-color: #f9f8fd;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}